import request from '@/utils/request'


// 查询用户-用户企业认证列表
export function listAuthCompany(query) {
  return request({
    url: '/user/auth-company/list',
    method: 'get',
    params: query
  })
}

// 查询用户-用户企业认证分页
export function pageAuthCompany(query) {
  return request({
    url: '/user/auth-company/page',
    method: 'get',
    params: query
  })
}

// 查询用户-用户企业认证详细
export function getAuthCompany(data) {
  return request({
    url: '/user/auth-company/detail',
    method: 'get',
    params: data
  })
}

// 新增用户-用户企业认证
export function addAuthCompany(data) {
  return request({
    url: '/user/auth-company/add',
    method: 'post',
    data: data
  })
}

// 修改用户-用户企业认证
export function updateAuthCompany(data) {
  return request({
    url: '/user/auth-company/edit',
    method: 'post',
    data: data
  })
}

// 删除用户-用户企业认证
export function delAuthCompany(data) {
  return request({
    url: '/user/auth-company/delete',
    method: 'post',
    data: data
  })
}
