<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item label="企业名称" prop="companyName" >
        <a-input v-model="form.companyName" placeholder="请输入企业名称" />
      </a-form-model-item>
      <a-form-model-item label="企业信用代码(营业执照编码)" prop="companyNo" >
        <a-input v-model="form.companyNo" placeholder="请输入企业信用代码(营业执照编码)" />
      </a-form-model-item>
      <a-form-model-item label="法人姓名" prop="legalName" >
        <a-input v-model="form.legalName" placeholder="请输入法人姓名" />
      </a-form-model-item>
      <a-form-model-item label="法人身份证号" prop="legalIdCardNo" >
        <a-input v-model="form.legalIdCardNo" placeholder="请输入法人身份证号" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="contactPhone" >
        <a-input v-model="form.contactPhone" placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="负责人" prop="contactName" >
        <a-input v-model="form.contactName" placeholder="请输入负责人" />
      </a-form-model-item>
      <a-form-model-item label="营业执照图片" prop="certImg" >
        <img v-if="form.certImg" :src="form.certImg"  style="width:60px;height:62px;" @preview="handlePreview"/>
      </a-form-model-item>
      <a-form-model-item label="是否审核通过" prop="isPass" >
        <a-radio-group v-model="form.isPass">
          <a-radio v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="审核原因 eg:通过/身份证信息有误" prop="auditReason" >
        <a-input v-model="form.auditReason" placeholder="请输入审核原因" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAuthCompany, addAuthCompany, updateAuthCompany } from '@/api/user/authCompany'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        companyName: null,

        companyNo: null,

        legalName: null,

        legalIdCardNo: null,

        contactPhone: null,

        certImg: null,

        isPass: 0,

        auditReason: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isPass: [
          { required: true, message: '审核状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        companyName: null,
        companyNo: null,
        legalName: null,
        legalIdCardNo: null,
        contactPhone: null,
        certImg: null,
        auditStatus: 0,
        auditReason: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAuthCompany({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            var idList = [];
            idList.push(this.form.id);
            this.form.idList = idList;
            updateAuthCompany(this.form).then(response => {
              this.$message.success(
                '审核成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAuthCompany(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.hide {
  /deep/ .ant-input {
    border: 0 !important;
  }
}
</style>