<template>
  <a-modal width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item label="是否审核通过" prop="isPass" >
        <a-radio-group v-model="form.isPass">
          <a-radio v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="审核原因" prop="auditReason" >
        <a-input v-model="form.auditReason" placeholder="可选" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import {  updateAuthCompany } from '@/api/user/authCompany'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'AuditForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        idList:null,
        isPass: null,
        auditReason: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        isPass: [
          { required: true, message: '审核状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        realName: null,
        contactPhone: null,
        idCardNo: null,
        idCardImgFace: null,
        idCardImgNational: null,
        isPass: 0,
        auditReason: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    handlePreview(file) {
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      this.form.idList = ids
      this.open = true
      this.formTitle = '审核'
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
            var idList = [];
            idList.push(this.form.id);
            this.form.idList = idList;
            updateAuthCompany(this.form).then(response => {
              this.$message.success(
                '审核成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
      })
    }
  }
}
</script>
<style scoped lang="less">
.hide {
  /deep/ .ant-input {
    border: 0 !important;
  }
}
</style>